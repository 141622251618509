.content{
    --background: #e9edf2;
}

.practiceLogo{
    width: 85%;
	height: 125px;
    object-fit: contain;
    margin: auto;
}

.checkCircle{    
    width: 150px; 
    overflow: hidden;
    margin: auto;
}

.invoiceBadge{
    --background: #ebedf2;
    --color: #3c92fd;
    width: 50px;
    height: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 1px;
    padding-left: 1px;
}

.patientNowLogo{
    width: 200px; 
    overflow: hidden;
    margin: auto;
    margin-bottom: 5px;  
}

.divVersion{
    width: 100%;
    text-align: center;
    color: #a0a5b9;
}

.imageBroken{
    margin-top: 50px;
    font-weight: bold;
    color: #231f20;
    letter-spacing: 0.3px;
}

h2{
    font-weight: 700 !important;
}

.padding-3{
    padding-top: 3px;
    padding-right: 0px;
    padding-bottom: 3px;
    padding-left: 0px;
    font-size: 13px !important;
}

.errorBox{
    --background: #fff2f0;
    border: 1px solid #fc3d3c;
}

.word-wrap {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  
  }

.transparentItem{
    --background: transparent;
}

.title{
    padding-top: 15px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    letter-spacing: 0.3px;
    color: #231f20;
}

.subtitle {
    font-size: 6.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    color: #231f20;
    padding: 15px;
  }

  .not-found {
    font-size: 100px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    color: #231f20;
    padding: 15px;
  }

.description{
    line-height: 1.67;
    letter-spacing: 0.3px;
    color: #231f20;
}

.description-small{
    font-weight: normal;
    letter-spacing: 0.24px;
    color: #a0a5b9;
}

.amounts{
    letter-spacing: 0.3px;
    text-align: right;
    color: #14d564;
}  

.sub-amounts{
    font-weight: normal;
    letter-spacing: 0.24px;
    color: #a0a5b9;
}

.boldH3{
    font-weight: 600 !important;
}

.item-active{
    border:1px solid #3c92fd;
    border-radius: 5px;
    --background:#dff2ff;    
}

.item-inactive{
    border:1px solid #ebedf2;
    border-radius: 5px;
}

.item-active-no-border{
    border-radius: 5px;
    --background:#dff2ff;    
}

.div-active{
    border: 1px solid #3c92fd;
    border-radius: 5px;
    background: #dff2ff;
}

.div-inactive{
    border:1px solid #ebedf2;
    border-radius: 5px;
}

.input-active{
    --background:#dff2ff;    
}

.fontSizeError{
    color: red;
    font-size: 12px;
}

.item-no-padding{
    /*padding: 0px 15px;*/
    --padding-start: 0px;
    --inner-padding-end: 0px;
}

.radio-ios-circle{
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 23px;
    height: 23px;
}

.action-button{
    height:42px;
    font-size:13px;
    font-weight:bold;
}

.fa-button-icon{
    padding-right:5px;
}

ion-item.item-input.item-has-value > ion-label{
    font-weight: bold;
}

ion-item.item-input:not(.item-has-value, .item-has-focus) > ion-label{
    color: #A0A5B9 !important;
}

a:link {
text-decoration: none;
}

a:visited {
text-decoration: none;
}

a:hover {
text-decoration: underline;
}

a:active {
text-decoration: underline;
}

.marginInline {
    margin-inline: 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
}

@media only screen and (max-width: 992px) {    
    .bigScreenLogo{
        display: none;
      }

    .smallScreenLogo{
      display: block;
    }
  }

  @media only screen and (min-width: 992px) {    
    .smallScreenLogo{
        display: none;
      }
  }  